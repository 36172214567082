export function apiListAdImageWrapper(res) {
    if (res?.resultItems && Array.isArray(res.resultItems)) {
        fixAdImages(res.resultItems);
    }
    return res;
}

function fixAdImages(images) {
    images
        .filter((item) => item.imageurl !== undefined)
        .forEach((item) => {
            item.imageurl = prependImageUrl(item.imageurl);

            return item.imageurl;
        });
}

export function apiRecommendedAdImageWrapper(res) {
    if (res?.items && Array.isArray(res.items)) {
        fixRecommendationImages(res.items);
    } else {
        try {
            const obj = JSON.parse(res);

            fixRecommendationImages(obj.items);

            // biome-ignore lint/style/noParameterAssign: This was created before the rule was enabled
            res = JSON.stringify(obj);
        } catch (error) {
            console.log(`apiRecommendedAdImageWrapper failed with ${error}`);
        }
    }

    return res;
}

function fixRecommendationImages(images) {
    images
        .filter((item) => item.image !== undefined && item.image.isAbsolute === false)
        .forEach((item) => {
            item.image.url = prependImageUrl(item.image.url);

            return item.image.url;
        });
}

export function prependImageUrl(imageUrl) {
    const bucket = imageUrl?.includes('fhh/') ? 'travel-images' : process.env.FIAAS_ENVIRONMENT !== 'prod' ? 'dev-images' : 'images';
    return `https://${bucket}.finncdn.no/dynamic/default/${imageUrl}`;
}
