export const handleFailure = (response) =>
    response.ok ? Promise.resolve(response.json()) : Promise.reject(`(${response.status}) ${response.statusText}: ${response.url}`);

export const handleFailureAsObject = (response) =>
    response.ok
        ? Promise.resolve(response.json())
        : Promise.reject({
              status: response.status,
              message: response.statusText,
              url: response.url,
          });

export const authRequest = (authCookies?: string, other: RequestInit = {}): RequestInit => {
    if (!authCookies) {
        return other;
    }
    return {
        ...other,
        headers: { cookie: authCookies, ...other.headers },
    };
};

export const createPost = (body): RequestInit => ({
    method: 'POST',
    headers: { 'content-type': 'application/json', 'x-requested-with': 'XMLHttpRequest' },
    body: body ? JSON.stringify(body) : '',
    credentials: 'include',
});

export const createFormPost = (form): RequestInit => ({
    method: 'POST',
    body: form,
    credentials: 'include',
});
